@media only screen and (max-width: 1550px) {
  #shopify-section-sections--18138550632675__custom_newsletter_discount_AmF3mQ .col-md-5{
    display: flex;
    justify-content: end;
    padding-right: 3rem;
  }
  #shopify-section-sections--18138550632675__custom_newsletter_discount_AmF3mQ .hdt-container .text-center-2 {
    font-size: 30px; 
  }
  img.newsletter-topimage.banner-float-image{
    width:80px;
  }
}
@media only screen and (max-width: 1180px) {
    #shopify-section-sections--18138550632675__custom_newsletter_discount_AmF3mQ .hdt-container .text-center-2 {
      font-size: 28px;
      line-height: normal;
    }
  .hdt-content-newsl .hdt-section-des p{
    font-size:12px;
  }
}
@media only screen and (max-width: 1100px) {
    #shopify-section-sections--18138550632675__custom_newsletter_discount_AmF3mQ .hdt-container .text-center-2 {
        font-size: 26px;
    }
}
@media only screen and (max-width: 1024px) {
    #shopify-section-sections--18138550632675__custom_newsletter_discount_AmF3mQ .hdt-container .text-center-2 {
        font-size: 24px;
    }
}
@media only screen and (max-width: 900px) {
  #shopify-section-sections--18138550632675__custom_newsletter_discount_AmF3mQ .hdt-container .text-center-2 {
      font-size: 22px;
  }
  .hdt-content-newsl .hdt-section-des p{
    font-size:10px;
    max-width: 60%;
    margin: auto;
  }
  #shopify-section-sections--18138550632675__custom_newsletter_discount_AmF3mQ .hdt-container.hdt-newsletter{
    flex-direction: column;
    padding: 0 10rem;
    row-gap:20px;
  }
  #shopify-section-sections--18138550632675__custom_newsletter_discount_AmF3mQ .col-md-5 {
    justify-content: center;
    padding-right:0;
  }
  .hdt-newsletter-wrap.hdt-block > .klaviyo-form-XgacHm{
    margin:auto;
  }
}
@media only screen and (max-width: 800px) {
  .hdt-content-newsl .hdt-section-des p {
    max-width: 100%; 
  }
}
.hdt-slider a.product-tag{
  right: -42px !important;
  z-index: 5;
  top: -42px;
  width: 90px;
  height: 120px;
  font-size: 13px;
}

.klaviyo-form-XgacHm.klaviyo-form{
  max-width: var(--width);
}
.klaviyo-form input.needsclick[type="email"]:hover,
.klaviyo-form input.needsclick[type="email"]:focus-visible{
  border-color: #fff !important;
  outline-color: #fff;
}
/* .klaviyo-form [data-testid="form-row"] div:nth-child(2){
  border-left:0 solid #f5f5f5;
  border-radius:4px 0 0 4px;
} */
@media screen and (max-width:900px){
  .klaviyo-form[data-testid="klaviyo-form-XgacHm"] [data-testid="form-row"] {
    flex-direction: column !important;
    position: unset;
    margin-top: 5rem;
    gap: 6px;
  }
}
/* .klaviyo-form-XgacHm button.needsclick {
  color:#000 !important;
  font-weight: 500 !important;
  --btn-color: rgb(var(--color-button-text));
  --btn-background-color: rgb(var(--color-button));
  --light_skew-color: rgb(var(--color-button-text)/.25);
  
  transition: background-color .25s ease-in-out,color .25s ease-in-out,border .25s ease-in-out;
  will-change: background-color,color,border;
  pointer-events: auto !important;
  overflow: hidden;
}
.klaviyo-form-XgacHm button.needsclick:after {
    background-image: linear-gradient(90deg,transparent,var(--light_skew-color,transparent),transparent);
    content: "";
    left: 150%;
    position: absolute;
    top: 0;
    bottom: 0;
    transform: skew(-20deg);
    width: 200%;
} */


#shopify-section-template--18138578288867__ingredients_section_9RLymy.shopify-section .organic-ingredients-section{
  padding-bottom:100px;
}
#innersection-template--18138578288867__image_with_text_PMAGcg,
#innersection-template--18138578288867__image_with_text_EAk8PE{
    margin-top: -5rem;
}
.hdt-section.hdt-image-with_text .sec-bottom-img{
  margin-bottom: -5rem;
  z-index: 1;
  position: relative;
}
#template--18138578288867__282a0160-505d-43ff-b601-043a5a327bc2 .hdt-container.hdt-container-heading{
  padding:top:4rem;
}
.custom-container .custom-col-12 .swiper-button-prev{
  left: 10px;  
}
.banner-float-image-all{
  overflow:hidden;
}
.banner-float-image-all .banner-float-image.banner-float-image-1{
  left:-1%;
}
.banner-float-image-all .banner-float-image.banner-float-image-2{
  right:-2% !important;
}
.product-imageslider .find-your-balance{
  margin-bottom: -2rem;
}

@media screen and (max-width:728px){
  #shopify-section-sections--18138550665443__header.hdt-section-header [data-header-height] {
    justify-content:space-between;
  }
  #shopify-section-sections--18138550665443__header.hdt-section-header .custom-head-menu.hdt-flex{
    justify-content:end;
  }
  .hdt-section.hdt-image-with_text .sec-bottom-img{
    margin-top:-1rem;
  }
  img.topimage {
    margin-bottom: -1rem;
  }
  .hdt-card-product .hdt-product-btns :where(wrapp-hdt-pr-frm,wrapp-hdt-open-modal-btn,.hdt-ultra_btn_parent) {
    box-shadow: unset !important;
  }
  img.bottomimage {
    margin-top: -1rem;
  }
  .banner-float-image-all .banner-float-image.banner-float-image-2 {
    right: -4%!important;
  }
  .banner-float-image-all .banner-float-image.banner-float-image-1 {
    left: -1% !important;
  }
  .section-allow-transparent .sec-top-img{
    margin-bottom: -1rem;
  }
  #shopify-section-template--18138549747939__image_with_text_nfNdzL.shopify-section {
    margin-bottom: 0;
  }
}
.hdt-main-product.product.merch .rc-container-wrapper {
  display:none;
}
.hdt-main-product.product.merch .tab-button[data-tab="benefits"],
.hdt-main-product.product.merch .tab-content#benefits{
  display:none;
}
.tab-content .bg-color{
  border-radius: 0 0 15px 15px !important; 
}
.product.merch .accordian_tabs .tabs-container .tab-content .item{
  border:0;
}
.product.merch .accordian_tabs .tabs-container .tab-content .item p{
  font-size: 16px;
  color: #262626;
  text-align: left;
}
a.product-tag{
  z-index:1;
}
.product.hdt-main-product .review-rating span p {
  font-family: 'Montserrat';
  text-align: center;
}
h1.custom-title.onlydeskop {
    font-size: 36px;
    line-height: 170%;
}
@media screen and (max-width:768px) {
  hdt-sticky-header .hdt-container {
    padding: 15px 0rem;
  }
  .product .onlydeskop {
    display:block;
  }
  .product .update-title.onlydeskop{
    margin-bottom: 1rem;
  }
  .product .products-image-other.onlydeskop {
    display:none;
  }
  .product .hdt-product-media-wrapp .onlymobile{
    display:none !important;
  }
  .product .hdt-product__price .hdt-price-wrapp{
    display:none !important;
  }
  .product.hdt-main-product.hdt-section-spacing.hdt-s-gradientt {
    padding-top: 1rem !important;
    padding-bottom: 10rem;
  }
  .product h2.custom-title.onlydeskop,
  .product h1.custom-title.onlydeskop {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 1rem;
  }
  .product span.custom-sub-title.onlydeskop {
    font-size: 17px;
  }
  .product.hdt-main-product .review-rating span p {
    font-size: 15px !important;
    text-align: left;
  }
  .product .melt-prod-tabs .tab-btn p {
    font-size: 18px;
  }
  .product .quick-fact div {
    margin-left: 0;
  }
  .product .quick-fact div p {
    font-size: 14px;
  }
  .product .accordian_tabs .tab-button {
    font-size: 15px;
    height: auto;
    margin-top: 2rem;
    text-transform: capitalize;
  }
  .product .accordian_tabs .tab-content .metafield-rich_text_field {
    font-family: 'Montserrat';
    font-size:14px;
    color:#262626;
  }
  #shopify-section-template--18138550436067__image_with_text_hEi7qD .hdt-content h2.hdt-heading {
    font-size: 26px !important;
    margin-bottom: 3rem;
  }
  #shopify-section-template--18138550436067__image_with_text_hEi7qD .metafield-rich_text_field{
    font-size: 15px;
    line-height: 26px;
    font-family: 'Montserrat';
    
  }
  #shopify-section-template--18138550436067__image_with_text_hEi7qD .metafield-rich_text_field p {
    font-size: 16px;
  }
  #shopify-section-template--18138550436067__image_with_text_hEi7qD .metafield-rich_text_field ul {
    margin-top: 2rem;
  }
  #shopify-section-template--18138550436067__image_with_text_hEi7qD .metafield-rich_text_field ul li{
    margin-top:8px;
  }
  #shopify-section-template--18138550436067__image_with_text_hEi7qD .hdt-container {
    padding:0;
  }
  #shopify-section-template--18138550436067__image_with_text_hEi7qD img.sec-bottom-img {
    margin-bottom: -1rem !important;
    z-index: 10;
  }
  #shopify-section-template--18138550436067__image_with_text_hEi7qD .metafield-rich_text_field {
    padding-bottom: 2rem;
  }
  #shopify-section-template--18138550436067__image_with_text_PMAGcg .metafield-rich_text_field p {
    font-size: 15px;
    line-height: 26px;
  }
  #innersection-template--18138550436067__image_with_text_PMAGcg .hdt-section-spacing {
    padding-bottom: 3rem;
  }
  #innersection-template--18138550436067__custom_video_with_text_dEmAKC h2 {
    text-transform: capitalize;
    line-height: 40px;
  }
  #innersection-template--18138550436067__custom_video_with_text_dEmAKC  .rating-section {
    gap: 1rem;
  }
  .section-testimonial #innersection-template--18138550436067__testimonial_section_2_hxrrtR section.testimonials-section.hdt-container {
    padding: 20px 0 70px !important;
  }
  #innersection-template--18138550436067__testimonial_section_2_hxrrtR h2.testimonial-title {
    font-size: 24px;
    text-transform: capitalize;
    line-height: 40px;
  }
  .find-your-balance .find-your-heading h2 {
    font-size: 24px;
    margin-top: 4rem;
  }
  .find-your-balance .find-your-heading p {
    font-size: 16px;
    width: 100%;
  }
  .Findbal-Swiper .Findbal-Swiper-content {
    padding: 30px 15px;
    height: 100%;
  }
  .Findbal-Swiper .Findbal-prod-price h5 {
    padding: 0 0 1rem;
    margin: auto;
  }
  .Findbal-Swiper .Findbal-Swiper-content a {
    font-size: 14px;
    padding: 8px 25px;
    margin: 2rem auto;
  }
  .Findbal-Swiper .Findbal-Swiper-content h3 {
    text-align: center;
  }
  .Findbal-Swiper .description {
    font-size: 13px !important;
    margin: 0 0 !important;
    text-align: center;
  }
  .hdt-section-icon-box .hdt-section-spacing {
    padding: 3rem 0 0;
  }
  #shopify-section-sections--18138550632675__icon_box_GWRcJf.hdt-section-icon-box .hdt-icon-box {
    padding-bottom: 125px;
  }
  .hdt-newsletter-bg img.newsletter-topimage.banner-float-image {
    width: 64px;
  }
}